import { AfterViewInit, Component, ComponentRef, EventEmitter, Injector, Input, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ComponentWithDataSetter, DropdownOption } from '../dropdown/types/dropdown-option.type';
import { NgClass } from '@angular/common';
import { SafeDomPipe } from '@shared/pipes/safe-dom.pipe';
import { AppService } from '@shared/services/app.service';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-dropdown-content',
  templateUrl: './dropdown-content.component.html',
  styleUrls: ['./dropdown-content.component.scss'],
  standalone: true,
  imports: [NgClass, SafeDomPipe]
})
export class DropdownContentComponent implements OnInit, AfterViewInit {
  @Input() hiddenOption?: DropdownOption;
  //pass an array of options to the dropdown
  @Input() dropdownOptions?: DropdownOption[] = [];
  //pass a custom component to the dropdown
  @Input() componentWithDataSetter?: ComponentWithDataSetter<any>;
  // Width for dropdown
  @Input() widthInPX: string = '187px';
  // Max Width for dropdown
  @Input() minWidthInPX: string = '70px';
  // Height for internal dropdown content
  @Input() heightInPX: number = 120;
  // Max Height for internal dropdown content
  @Input() maxHeightInPX: number = 340;
  // The flag to handle the style of the dropdowns for a field
  @Input() isField: boolean = false;
  // The selected option object
  @Input() selectedOption?: DropdownOption;
  @ViewChild('dynamicTarget', { read: ViewContainerRef, static: true }) target: any;

  @Output() optionClicked: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();

  private _customComponent?: ComponentRef<any>;
  public containerMaxHeight?: number;
  private _destroy$: Subject<void> = new Subject<void>();

  constructor(
    private injector: Injector,
    private _AppService: AppService,
  ) { }

  ngOnInit() {
    this._createComponent();
    this._AppService.dropdownChanges$.pipe(takeUntil(this._destroy$)).subscribe({
      next: (value) => this.dropdownOptions = value
    })
  }

  ngAfterViewInit(): void {
    this._createInitialMaxHeight();
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
  * @description Create initial max height for custom component calculated from current height
  * @returns void
  */
  private _createInitialMaxHeight(): void{
    const dropdownListContainer = document.querySelector('.dropdown-list-container') as HTMLElement
    this.containerMaxHeight = dropdownListContainer?.getBoundingClientRect().height;
  }

  /**
  * @description Create the dropdown component list
  * @returns void
  */
  private _createComponent(): void {
    // Check if the component is defined
    if (!this.componentWithDataSetter?.component) return;
    // Clear previous content inside dynamicTarget
    this.target.clear();
    // Create the component inside dynamicTarget ViewContainerRef
    const componentRef = this.target.createComponent(this.componentWithDataSetter.component, {
      injector: this.injector,
    });
    // Set the data for the component using the provided data setter
    this.componentWithDataSetter.dataSetter(componentRef.instance);
    // Store the component reference for potential further use
    this._customComponent = componentRef;
  }

  /**
  * @description emit the selected option when click on the option
  * @param option DropdownOption
  */
  public onOptionClicked(option: DropdownOption): void{
    this.optionClicked.emit(option);
  }
}
