import { NgClass } from '@angular/common';
import {
  Component,
  ComponentRef,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SafeDomPipe } from '@shared/pipes/safe-dom.pipe';
import { ComponentWithDataSetter, DropdownOption } from './types/dropdown-option.type';
import { Subject, takeUntil } from 'rxjs';
import { DropdownContentComponent } from '../dropdown-content/dropdown-content.component';

type yPositions = 'top' | 'bottom'

type xPositions = 'left' | 'right'

@Component({
  selector: 'app-dropdown',
  standalone: true,
  imports: [ReactiveFormsModule, NgClass, SafeDomPipe],
  templateUrl: './dropdown.component.html',
  styleUrl: './dropdown.component.scss',
})
export class DropdownComponent {
  @Input() hiddenOption?: DropdownOption;
  //pass an array of options to the dropdown
  @Input() dropdownOptions?: DropdownOption[] = [];
  //pass a custom component to the dropdown
  @Input() componentWithDataSetter?: ComponentWithDataSetter<any>;
  // Width for dropdown
  @Input() widthInPX: string = '187px';
  // Min Width for dropdown
  @Input() minWidthInPX: string = '70px';
  // Height for internal dropdown content
  @Input() heightInPX: number = 120;
  // Max Height for internal dropdown content
  @Input() maxHeightInPX: number = 340;
  // The x position of the dropdown list
  @Input() dropdownListXPosition: xPositions = 'left';
  // The y position of the dropdown list
  @Input() dropdownListYPosition: yPositions = 'bottom';
  // The flag to handle the style of the dropdowns for a field
  @Input() isField: boolean = false;
  // The flag to disable the dropdown
  @Input() disableDropdown: boolean = false;
  @Output() dropdownClosed: EventEmitter<void> = new EventEmitter<void>();
  @Output() optionClicked: EventEmitter<DropdownOption> = new EventEmitter<DropdownOption>();
  @ViewChild('dropdown') dropdown!: ElementRef<HTMLElement>

  selectedOptionIcon: string = `<svg
  width="16"
  height="16"
  viewBox="0 0 16 16"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
  >
  <path
    d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346627 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6569C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C15.9977 5.87898 15.1541 3.8455 13.6543 2.34572C12.1545 0.845932 10.121 0.00232928 8 0ZM10.9656 6.9656L7.7656 10.1656C7.61558 10.3156 7.41213 10.3998 7.2 10.3998C6.98787 10.3998 6.78442 10.3156 6.6344 10.1656L5.0344 8.5656C4.88868 8.41472 4.80804 8.21263 4.80986 8.00288C4.81169 7.79312 4.89582 7.59247 5.04415 7.44414C5.19247 7.29582 5.39312 7.21168 5.60288 7.20986C5.81264 7.20804 6.01472 7.28867 6.1656 7.4344L7.2 8.4688L9.8344 5.8344C9.98528 5.68867 10.1874 5.60804 10.3971 5.60986C10.6069 5.61168 10.8075 5.69582 10.9559 5.84414C11.1042 5.99247 11.1883 6.19312 11.1901 6.40288C11.192 6.61263 11.1113 6.81472 10.9656 6.9656Z"
    fill="#1F6FE0"
  />
  </svg>`;
  private _selectedOption?: DropdownOption;
  private _dropdownRef?: ComponentRef<DropdownContentComponent>;
  private _destroy$: Subject<void> = new Subject<void>();
  constructor(
    private injector: Injector,
    private viewContainerRef: ViewContainerRef,
  ){}

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  /**
  * @description Toggle dropdown list
  * @param event accepts Event
  * @returns void
  */
  public toggleDropdown(event: Event): void {
    if (this._dropdownRef)
      this._removeDropdown();
    else
      this._createDropdownList(event);
  }

  /**
  * @description Create dropdown list
  * @param event accepts Event
  * @returns void
  */
  private _createDropdownList(event: Event): void {
    if(this.disableDropdown) return;
    // Create the component using ViewContainerRef
    this._dropdownRef = this.viewContainerRef.createComponent(DropdownContentComponent, {
      injector: this.injector,
    });
    if(this.isField)
      this.widthInPX = this.dropdown.nativeElement.getBoundingClientRect().width + 'px';
    this._initializeCreatedDropdownData();

    const domElem = (this._dropdownRef.hostView as any).rootNodes[0] as HTMLElement;

    // Create overlay
    const overlayElement = document.createElement('div');
    overlayElement.classList.add("dropdown-list-overlay", "w-screen", "h-screen", "opacity-0", "fixed", "top-0", "left-0", "z-[9999]");
    document.body.appendChild(overlayElement);

    // Append the dropdown to the body
    document.body.appendChild(domElem);

    setTimeout(() => {
      this._placeDropdown(event);
      // Listen for click outside to close the dropdown
      document.addEventListener('mousedown', this._onDocumentClick);
    },this.isField ? 100 : 0);
  }

  /**
  * @description Initialize the created dropdown inputs & outputs
  * @returns void
  */
  private _initializeCreatedDropdownData(): void{
    if(!this._dropdownRef) return;
    this._dropdownRef.instance.componentWithDataSetter = this.componentWithDataSetter;
    this._dropdownRef.instance.dropdownOptions = this.dropdownOptions;
    this._dropdownRef.instance.widthInPX = this.widthInPX;
    this._dropdownRef.instance.minWidthInPX = this.minWidthInPX;
    this._dropdownRef.instance.heightInPX = this.heightInPX;
    this._dropdownRef.instance.maxHeightInPX = this.maxHeightInPX;
    this._dropdownRef.instance.hiddenOption = this.hiddenOption;
    this._dropdownRef.instance.isField = this.isField;
    this._dropdownRef.instance.selectedOption = this.isField ? this._selectedOption : undefined;
    this._dropdownRef.instance.optionClicked.pipe(takeUntil(this._destroy$)).subscribe({
      next: (data)=> {
        this._removeDropdown();
        this.optionClicked.emit(data);
        this._selectedOption = data
      }
    });
  }

  /**
  * @description Place dropdown component functionalities
  * @param event accepts Event
  * @returns void
  */
  private _placeDropdown(event: Event){
    const element = this.dropdown.nativeElement || (event.target as HTMLElement)
    const domElem = document.querySelector('app-dropdown-content') as HTMLElement;
    const top = element.getBoundingClientRect().top - domElem.getBoundingClientRect().height + window.scrollY;
    const down = element.getBoundingClientRect().top + element.getBoundingClientRect().height + window.scrollY;
    const left = Math.abs(element.getBoundingClientRect().left - Math.abs(domElem.getBoundingClientRect().width - element.getBoundingClientRect().width) + window.scrollX);
    const right = element.getBoundingClientRect().left + window.scrollX;
    let isInMiddleOfScreen: boolean = false;
    switch (this.dropdownListYPosition) {
      case 'top':{
        if(top < 0){
          if(down + domElem.getBoundingClientRect().height +window.scrollY > window.innerHeight){
            isInMiddleOfScreen = true;
            domElem.style.top = '50%';
            domElem.style.transform = 'translateY(-50%)';
          }
          else domElem.style.top = `${down}px`;
        }else domElem.style.top = `${top}px`;
      break;
      }
      case 'bottom':{
        if(down + domElem.getBoundingClientRect().height + window.scrollY > window.innerHeight){
          if(top < 0){
            isInMiddleOfScreen = true;
            domElem.style.top = '50%';
            domElem.style.transform = 'translateY(-50%)';
          }
          else domElem.style.top = `${top}px`;
        }else domElem.style.top = `${down}px`;
        break;
      }
    }
    switch (this.dropdownListXPosition) {
      case 'left':{
        if(this.isField){
          domElem.style.left = element.getBoundingClientRect().left + 'px'
          break;
        }
        if(left > element.getBoundingClientRect().left)
          domElem.style.left = `${right}px`;
        else
          domElem.style.left = `${left}px`;
        break;
      }
      case 'right': {
        if(this.isField) break;
        if(right + parseFloat(this.widthInPX) > window.innerWidth)
          domElem.style.left = `${left}px`;
        else
          domElem.style.left = `${right}px`;
        break;
      }
    }
    //Move the dropdown content to the left or the right if it's in the middle in Y axis
    if(isInMiddleOfScreen){
      switch (this.dropdownListXPosition) {
        case 'left':{
          domElem.style.left = domElem.getBoundingClientRect().left - element.getBoundingClientRect().width + 'px';
          break;
        }
        case 'right':{
          domElem.style.left = domElem.getBoundingClientRect().left + element.getBoundingClientRect().width + 'px';
          break;
        }
      }
    }
    domElem.classList.add('fade-in-animation')
  }

  /**
  * @description Remove the dropdown list
  * @returns void
  */
  private _removeDropdown(): void {
    if (this._dropdownRef) {
      this._dropdownRef.destroy();
      this._dropdownRef = undefined;
      document.querySelector('.dropdown-list-overlay')?.remove();
      document.removeEventListener('mousedown', this._onDocumentClick);
      this.dropdownClosed.emit();
    }
  }

  /**
  * @description Handle click outside the dropdown
  * @param event accepts MouseEvent
  * @returns void
  */
  private _onDocumentClick = (event: MouseEvent): void => {
    if (this._dropdownRef && !this.dropdown.nativeElement.contains((event.target as HTMLElement)) && !(event.target as HTMLElement).closest('.dropdown-list-container'))
      this._removeDropdown();
  };
}
